import { FC, HTMLAttributes } from 'react';

export interface ContracrTableProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  desc?: string;
}

export const ContractTable: FC<ContracrTableProps> = ({ title, desc }) => {
  return (
    <>
      <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
        <p>{title}</p>
      </div>
      <div className="col-span-5 grid content-center px-4">
        <div className="flex items-center space-x-2 text-15 text-gray-700">
          {desc}
        </div>
      </div>
    </>
  );
};
