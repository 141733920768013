import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Checkbox } from '../../../components/Checkbox';
import { ContractDateViewTable } from '../../../components/ContractDateViewTable';
import { ContractDesc } from '../../../components/ContractDesc';
import { ContractInfoViewTable } from '../../../components/ContractInfoViewTable';
import { ContractWageViewTable } from '../../../components/ContractWageViewTable';
import { SCV } from '../../../constants';
import { useContractById } from '../../../hooks/contract';

export const ContractDetailPage = () => {
  const { id: contractId } = useParams<{ id: string }>();
  const { data: contract } = useContractById(+(contractId as string) || 0);

  if (!contract) {
    return <></>;
  }

  return (
    <>
      <div className="mt-32 grid w-[1024px] place-items-center md:w-full">
        <div className="mt-8 w-full max-w-4xl">
          <h2 className="mt-10 text-32 font-bold leading-normal">
            근로계약서 작성(급여신청)
          </h2>
          <h4 className="mt-10">일일근로계약서</h4>
          <div className="mt-5 grid gap-y-12">
            <ContractInfoViewTable />
            <ContractDesc
              title="1. 계약기간"
              desc="본 계약은 1일 단위의 체결이며, 업무시간 종료시 자동 계약만료된다."
            />
            <ContractDesc
              title="2. 근무장소"
              desc="위 사용자와 근로자가 협의한 위 근무장소.(본인사정으로 인한 변동시 바뀔수 있음)"
            />
            <ContractDesc
              title="3. 해당업무"
              desc="사용자와 근로자가 사전에 협의한 업무. [ 홀서빙, 주방보조, 기물보조, 안내 등]
            "
            />
            <div className="space-y-2">
              <ContractDesc title="4. 계약기간" />
              <ContractDateViewTable />
              <p className="text-15">
                ※ 근무시간은 실제로 근무한 시간을 기입해주세요.
              </p>
            </div>
            <div className="space-y-2">
              <ContractDesc title="5. 근로임금" />
              <ContractWageViewTable contract={contract} />
              <div className="text-15">
                ※ 기본시급은 홈페이지에 공지된 시급이며 별도의 안내를 받으신분은
                따로 기입후 지급됨
                <br />
                ※ 임금은 근무 후 익 주 월~수요일 19시 지급이며 근무업장 사정에
                따라 최대 7일, 최소 2시간 지연 입금 될 수 있다.
                <br />※ 우리은행 외 타행으로 급여이체 받을 시 이체수수료 500원이
                공제되어 지급됩니다
              </div>
            </div>
            <ContractDesc
              title="6. 적용제외"
              desc="근로기준법상 단기간 근로자는 근로기준법의 주휴일, 연차휴가, 퇴직금 규정을 적용하지 아니하며, 기타 관련 사항은 회사 규정에 따른다."
            />
            <ContractDesc
              title="7. 고용보험"
              desc="임금지급 시 근로자부담금인 고용보험료[급여액의 0.65%]는 공제 후 입금된다."
            />
            <ContractDesc
              title="8. 손해배상"
              desc="근로자가 업무수행 중 업무와 무관하게 고의 또는 중과실로 사용자 와 근무장소에 재산상의 손해를 끼쳤을 때에는 원상회복 또는 손해배상을 하여야 한다."
            />
            <ContractDesc
              title="9. 기타사항"
              desc="본 계약에 명시하지 아니한 사항은 사용자가 별도로 정한 회사규정 및 근로기준법 등을 적용한다."
            />
            <ContractDesc
              title="10. 개인정보"
              desc="본 계약과 관련하여 사용자는 위 개인정보를 엄격히 관리하며, 근로자는 위 사용자가 DB(회원관리) 및 근로소득관련 제출증빙 등으로 근로자의 개인정보 보관 등에 관하여 동의한다."
            />
            {contract?.receiptUrl && (
              <div>
                <div className="mb-1 text-14 text-gray-600">영수증</div>
                <div className="flex w-max items-center justify-between rounded-md border border-gray-100 shadow-sm">
                  <img
                    className="h-40 w-40 rounded-md object-cover object-center"
                    src={contract?.receiptUrl}
                    alt=""
                    crossOrigin="anonymous"
                  />
                </div>
              </div>
            )}
            <p className="place-self-center">
              {contract?.createdAt &&
                moment(contract?.createdAt).format('yyyy-MM-DD')}
            </p>
            <div className="grid w-full grid-cols-2 divide-x rounded-md border ">
              <div>
                <div className=" grid h-10 place-items-center border-b bg-sub-3">
                  <p>사용자</p>
                </div>
                <div className="grid h-20 content-center px-4">
                  <p>
                    {SCV.companyName} {SCV.user}
                  </p>
                </div>
              </div>
              <div>
                <div className=" grid h-10 place-items-center border-b bg-sub-3">
                  <p>근로자</p>
                </div>
                <div className="grid h-20 content-center px-4">
                  <div className="flex space-x-2">
                    <p className="text-15 text-gray-700">이름</p>
                    <p>{contract?.userName}</p>
                  </div>
                  <div className="flex space-x-2">
                    <p className="text-15 text-gray-700">주민등록번호</p>
                    <p>{contract?.userPersonalId}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center space-y-5 place-self-center">
              <div className="flex items-center space-x-2">
                <Checkbox className="h-5 w-5" checked={true} />
                <h4>확인 동의</h4>
              </div>
              <p>
                ※ 급여/비용 청구 페이지에서 단기간근로계약서 및 일일계약서를
                확인할 수 있습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
