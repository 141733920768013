import { floor, isInteger } from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useContractById } from '../hooks/contract';

export function ContractDateViewTable() {
  const { id: contractId } = useParams<{ id: string }>();
  const { data: contract } = useContractById(+(contractId as string) || 0);

  const floatToTime = (time: number) => {
    if (isInteger(time)) {
      if (time < 10) {
        return `0${time}:00`;
      }
      return `${time}:00`;
    }
    const _float = +(time - floor(time)).toFixed(1);

    if (time < 10) {
      if (_float === 0.1) {
        return `0${floor(time)}:06`;
      }
      return `0${floor(time)}:${_float * 10 * 6}`;
    }

    if (_float === 0.1) {
      return `${floor(time)}:06`;
    }
    return `${floor(time)}:${_float * 10 * 6}`;
  };

  if (!contract) {
    return <></>;
  }

  return (
    <>
      <div className="">
        <div className=" grid h-64 w-full grid-cols-6  divide-y divide-x rounded-md border">
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1 after:content-['*']">
              계약일시
            </p>
          </div>
          <div className="col-span-5 grid content-center px-4">
            <div className="flex items-center space-x-2 text-15 text-gray-700">
              <p>{moment(contract?.contractDate).format('yyyy-MM-DD')}</p>

              <p>[1일]</p>
            </div>
          </div>
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1 after:content-['*']">
              근무시간
            </p>
          </div>
          <div className="col-span-5 grid content-center px-4">
            <div className="flex items-center space-x-3">
              <p>
                {contract.payment.hourPay !== 0
                  ? floatToTime(contract.payment.startWorkHour)
                  : moment(contract?.startWorkTime).format('HH:mm')}
              </p>
              <span className=""> ~ </span>
              <p>
                {contract.payment.hourPay !== 0
                  ? floatToTime(contract.payment.endWorkHour)
                  : moment(contract?.endWorkTime).format('HH:mm')}
              </p>
            </div>
          </div>
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1">휴게시간</p>
          </div>
          <div className="col-span-5 grid content-center px-4">
            <div className="flex items-center space-x-2 text-15 text-gray-700">
              <p>
                8시간 미만시 30분제공/ 8시간 이상시 1시간 제공
                <br />※ 단, 휴게시간은 행사 성격에 따라 변경될 수 있으며,
                휴게시간은 임금에 산정하지 않는다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
