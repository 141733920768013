import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { floor } from 'lodash';
import { TIME_STAMP } from '../admin/pages/company/types';
import { Contract, CreateContractDto } from '../api/contract/type';
import { ContractTimeList } from '../pages/contract/ContractAddPage';
import { Select } from './Select';
import { TextField } from './TextField';

const HOURS = new Array(24).fill(0).map((_, i) => i);
const MINUTES = new Array(60).fill(0).map((_, i) => i);

interface ContractDateEditTableProps {
  contract: Contract;
  contractState: CreateContractDto;
  setContractState: (contractState: CreateContractDto) => void;
  timeList: ContractTimeList;
  setTimeList: (time: ContractTimeList) => void;
}

export default function ContractDateEditTable({
  contract,
  contractState,
  setContractState,
  timeList,
  setTimeList,
}: ContractDateEditTableProps) {
  const { contractDate } = contractState;
  const [editContractDate, setEditContractDate] = useState('');
  const [workTime, setWorkTime] = useState<{
    startWorkHour: number | null;
    startWorkMin: number | null;
    endWorkHour: number | null;
    endWorkMin: number | null;
  }>({
    startWorkHour: null,
    startWorkMin: null,
    endWorkHour: null,
    endWorkMin: null,
  });

  const startHour = new Date(contractState.startWorkTime).getHours();
  const startMinute = new Date(contractState.startWorkTime).getMinutes();
  const endHour = new Date(contractState.endWorkTime).getHours();
  const endMinute = new Date(contractState.endWorkTime).getMinutes();

  useEffect(() => {
    if (!contractState || !contractDate) {
      return;
    }
    const edit = format(new Date(contractDate), 'yyyy-MM-dd');
    setEditContractDate(edit);
  }, [contractState]);

  useEffect(() => {
    if (contract.payment.hourPay !== 0) {
      const _startWorkHour = floor(contract.payment.startWorkHour);
      const _startWorkMin = +(
        (contract.payment.startWorkHour -
          floor(contract.payment.startWorkHour)) *
        10 *
        6
      ).toFixed(1);
      const _endWorkHour = floor(contract.payment.endWorkHour);
      const _endWorkMin = +(
        (contract.payment.endWorkHour - floor(contract.payment.endWorkHour)) *
        10 *
        6
      ).toFixed(1);
      setWorkTime({
        startWorkHour: _startWorkHour,
        startWorkMin: _startWorkMin,
        endWorkHour: _endWorkHour,
        endWorkMin: _endWorkMin,
      });
    }
  }, [contract]);

  return (
    <>
      <div>
        <div className=" grid h-64 w-full grid-cols-6  divide-y divide-x rounded-md border">
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1 after:content-['*']">
              계약일시
            </p>
          </div>
          <div className="col-span-5 grid content-center px-4">
            <div className="flex items-center space-x-2 text-15 text-gray-700">
              <TextField
                compulsory
                placeholder="근무한 날짜를 선택해주세요."
                type="date"
                max="9999-12-31"
                value={editContractDate ? editContractDate : contractDate}
                onChange={(e) => {
                  setContractState({
                    ...contractState,
                    contractDate: e.target.value,
                  });
                }}
              />

              <p>[1일]</p>
            </div>
          </div>
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1 after:content-['*']">
              근무시간
            </p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-2 text-15 text-gray-700">
                <Select
                  className="w-32"
                  disabled={contract.payment.hourPay !== 0}
                  onChange={(e) =>
                    setTimeList({ ...timeList, startHour: +e.target.value })
                  }
                >
                  {HOURS.map((hour) => (
                    <option
                      key={hour}
                      value={hour}
                      selected={
                        workTime.startWorkHour !== null
                          ? hour === workTime.startWorkHour
                          : hour === startHour
                      }
                    >
                      {TIME_STAMP[hour]}
                    </option>
                  ))}
                </Select>
                <Select
                  disabled={contract.payment.hourPay !== 0}
                  className="w-32"
                  onChange={(e) =>
                    setTimeList({
                      ...timeList,
                      startMin: +e.target.value,
                    })
                  }
                >
                  {MINUTES.map((min) => (
                    <option
                      key={min}
                      value={min}
                      selected={
                        workTime.startWorkMin !== null
                          ? min === workTime.startWorkMin
                          : min === startMinute
                      }
                    >
                      {TIME_STAMP[min]}
                    </option>
                  ))}
                </Select>

                <p>{' ~ '}</p>

                <div className="flex items-center space-x-2 text-15 text-gray-700">
                  <Select
                    disabled={contract.payment.hourPay !== 0}
                    className="w-32"
                    onChange={(e) =>
                      setTimeList({
                        ...timeList,
                        endHour: +e.target.value,
                      })
                    }
                  >
                    {HOURS.map((hour) => (
                      <option
                        key={hour}
                        value={hour}
                        selected={
                          workTime.endWorkHour !== null
                            ? hour === workTime.endWorkHour
                            : hour === endHour
                        }
                      >
                        {TIME_STAMP[hour]}
                      </option>
                    ))}
                  </Select>
                  <Select
                    disabled={contract.payment.hourPay !== 0}
                    className="w-32"
                    onChange={(e) =>
                      setTimeList({
                        ...timeList,
                        endMin: +e.target.value,
                      })
                    }
                  >
                    {MINUTES.map((min) => (
                      <option
                        key={min}
                        value={min}
                        selected={
                          workTime.endWorkMin !== null
                            ? min === workTime.endWorkMin
                            : min === endMinute
                        }
                      >
                        {TIME_STAMP[min]}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1">휴게시간</p>
          </div>
          <div className="col-span-5 grid content-center px-4">
            <div className="flex items-center space-x-2 text-15 text-gray-700">
              <p>
                8시간 미만시 30분제공/ 8시간 이상시 1시간 제공
                <br />※ 단, 휴게시간은 행사 성격에 따라 변경될 수 있으며,
                휴게시간은 임금에 산정하지 않는다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
