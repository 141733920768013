import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FifthSection } from '../../components/LandingPage/FifthSection';
import { FirstSection } from '../../components/LandingPage/FirstSection';
import { FourthSection } from '../../components/LandingPage/FourthSection';
import { SecondSection } from '../../components/LandingPage/SecondSection';
import { ThirdSection } from '../../components/LandingPage/ThirdSection';

export const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/main', { replace: true });
  }, []);

  return (
    <div className="space-y-20 md:space-y-24">
      <FirstSection />

      <SecondSection />

      <ThirdSection />

      <FourthSection />

      <FifthSection />
    </div>
  );
};
